.nav {
  background-color: #111;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 30px;
  top: 0;
  z-index: 11;
}

.nav__contents {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.nav__logo {
  position: fixed;
  left: 0;

  height: 34px;
  object-fit: contain;
  margin-left: 20px;
  cursor: pointer;
}

.nav__cart {
  cursor: pointer;
  width: 26px !important;
  height: 26px !important;
  color: rgb(255, 255, 255);
}

.nav__cartLink {
  margin-right: 10px;
  position: absolute;
  top: -7px;
  right: 0;
}

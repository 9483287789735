.subtotal {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;

  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;
}

.subtotal__orderInfo {
  font-size: 1.2rem;
  font-weight: 700;
}

.subtotal__orderRow {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: gray;
  margin-top: 10px;
}

.subtotal__subtotalPrice {
  color: #111;
}

.subtotal__proceedButton {
  background-color: #f8c33b;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;

  color: #111;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.subtotal__proceedButton:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

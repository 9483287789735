.faqScreen {
  margin-top: 90px;

  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.faqScreen > h1 {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 6px;
  font-family: boldFont;
  padding-bottom: 5px;
}

.footer {
  width: auto;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 40px;
  margin-bottom: 20px;

  padding-left: 20px;
  padding-right: 20px;

  justify-items: center;
  justify-content: center;
}

.footer__columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.footer__column {
  display: flex;
  flex-direction: column;
}

.footer__label {
  font-weight: 600;
  color: #1a1a1a;

  margin-bottom: 10px;
}

.footer__logo {
  width: 220px;
  margin-left: 20px;
  object-fit: contain;
  cursor: pointer;
}

.footer__link {
  color: #747474;

  text-decoration: none;
  font-weight: 600;
  margin-bottom: 6px;
}

.footer__IGLogo {
  color: #747474;
}

@media (min-width: 600px) {
  .footer {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .footer__logo {
    width: 280px;
  }
  .footer__label {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .footer__link {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .footer__IGLogo {
    width: 30px !important;
    height: 30px !important;
  }
}

.cartScreen {
  margin-top: 70px;
}

.cartScreen__nav {
  display: flex;
  background-color: white;

  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 6;

  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;
}

.cartScreen__backButton {
  position: absolute;

  color: rgb(112, 112, 112);
  border-radius: 5px;
  border: 2px solid rgb(179, 179, 179);
  width: 26px;
  height: 26px;

  cursor: pointer;
}

.cartScreen__back {
  width: 14px !important;
  text-align: center;
  padding-left: 1px;
  margin-top: -2px;
}

.cartScreen__myCart {
  font-size: 1.2rem;
  color: rgb(112, 112, 112);
  width: 100%;
  text-align: center;
}

.cartScreen__products {
  margin-bottom: 10px;
  margin-top: 0;

  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;
}

.cartScreen__subtotal {
  position: absolute;
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.cartScreen__emptyCart {
  font-size: 1.2rem;
  color: rgb(112, 112, 112);
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.extraSpace {
  height: 180px;
}

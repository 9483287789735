@font-face {
  font-family: boldFont;
  src: url("./../../fonts/theboldfont.ttf");
}

.products {
  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 10px;
  margin-bottom: 40px;

  padding-left: 10px;
  padding-right: 10px;
}

.products > h5 {
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
}

.products > h1 {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 30px;
  margin-bottom: 6px;
  font-family: boldFont;
}

.products__productContainer {
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (min-width: 400px) {
  .products > h1 {
    font-size: 1.8rem;
    margin-top: 60px;
    margin-bottom: 10px;
  }
}

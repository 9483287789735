.contactScreen {
  margin-top: 70px;
  padding-top: 20px;
  padding-bottom: 10px;

  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;
}

.contactScreen > h1 {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 6px;
  font-family: boldFont;
  padding-bottom: 5px;
}

.contactScreen__p {
  margin-bottom: 10px;
  color: #747474;
}

.contactScreen > form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contactScreen > form > .form__element {
  display: flex;
  flex-direction: column;
}

.contactScreen > form > .form__element > label {
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: 600;
}

.contactScreen > form > .form__element > input {
  background-color: #e9e9e9;

  padding: 16px;
  outline-width: 0;
  border: none;
  flex: 1;
  border-radius: 4px;
}

.contactScreen > form > .form__element > textarea {
  background-color: #e9e9e9;

  padding: 16px;
  outline-width: 0;
  border: none;
  flex: 1;
  border-radius: 4px;
  font-family: sans-serif;
  height: 200px;
}

.form__submitButton {
  background-color: #f8c33b;
  color: #f1f5f8;

  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;

  color: #111;
  font-size: 1rem;
  font-weight: 600;

  margin-top: 20px;

  cursor: pointer;
}

.contactScreen__socials {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  justify-content: center;
  align-content: center;
  align-items: center;
}
.contactScreen__IGIcon {
  margin-left: 6px;
  margin-top: 2px;
  color: #111;
}

@media (min-width: 900px) {
  .contactScreen {
    margin-top: 120px;
  }
}

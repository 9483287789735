@font-face {
  font-family: mainFont;
  src: url("./../../fonts/Supercarver.otf");
}
@font-face {
  font-family: boldFont;
  src: url("./../../fonts/theboldfont.ttf");
}

.about {
  width: auto;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;

  margin-top: 40px;
  margin-bottom: 40px;
}

.about__titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.about__title1 {
  text-align: center;
  font-size: 1.4rem;

  font-family: boldFont;
}
.about__title2 {
  font-family: mainFont;
  font-size: 1.4rem;
  margin-left: 10px;
  text-align: center;
  margin-bottom: 8px;
}

.about__container {
  display: flex;
  flex-direction: column;
}

.about__img {
  height: 340px;
  border-radius: 3px;
}

.about__paragraph {
  padding-top: 12px;
  font-size: 1rem;
}

.about__container > p > span {
  font-family: mainFont;
}

.about__heart {
  height: 12px !important;
}

@media (min-width: 400px) {
  .about__img {
    height: 420px;
  }
}
@media (min-width: 480px) {
  .about__img {
    height: 540px;
  }
}
@media (min-width: 600px) {
  .about__img {
    height: 720px;
  }
  .about__paragraph {
    padding-top: 20px;
    font-size: 1.2rem;
  }
  .about__title1 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .about__title2 {
    font-size: 1.8rem;
    margin-left: 16px;
  }
}

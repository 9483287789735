.product {
  min-width: 4rem;
  border-radius: 5px;
  margin: 10px;
  min-height: 60px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.product__img {
  min-height: 220px;
  height: 220px;
  border-radius: 5px;

  display: flex;
  position: relative;
}

.product__tag {
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.tag__soldOut {
  background-color: rgb(235, 15, 15);
}
.tag__limitedEd {
  background-color: #f8c33b;
  color: rgb(0, 0, 0);
}

.product__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.product__title {
  font-weight: 600;
  margin: 4px;
}

.product__price {
  font-size: 14px;
}

.productModal {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  visibility: visible;

  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
}

.productModal__productView {
  background: rgb(255, 255, 255);
  width: 92%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;

  max-width: 380px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.productModal__imageGallery {
  background-color: rgb(245, 245, 245);
  height: 200px;

  position: relative;
  overflow: hidden;
}

.productModal__carousel {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 200;
}

.productModal__closeButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.233);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  z-index: 10;
}
.productModal__imageCont {
  /*height: 260px;*/
}

.productModal__image {
  /*
  width: 100%;
  min-width: 100%;
  margin-bottom: auto;
  min-height: 260px;
  height: 260px;
  */

  object-position: center top;

  height: auto;
  max-height: 280px;
  object-fit: contain;
  z-index: 200;
}

.productModal__productInfo {
  height: auto;

  display: flex;
  flex-direction: column;
  position: relative;
}

.productModal__nameprice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px 6px 20px;

  font-size: 0.9rem;
}

.productModal__name {
  margin-right: 20px;
}

.productModal__descriptionstock {
  margin: 0px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.productModal__description {
  color: #747474;
  margin-bottom: 6px;
}
.productModal__stock {
  color: #747474;
}
.productModal__stock > span {
  color: #000000;
  font-weight: 600;
}

.productModal__underlayClose {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.productModal__addButton {
  background-color: #f8c33b;
  border: none;
  border-radius: 5px;
  width: auto;
  padding: 1rem;

  color: #111;
  font-size: 1rem;
  font-weight: 600;

  margin: 10px 20px 20px 20px;

  cursor: pointer;
}
.productModal__addButton:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.productModal__amountButton {
  color: rgb(78, 78, 78);
  border-radius: 16px;
  border: 1px solid rgb(179, 179, 179);
  background-color: white;
  width: 26px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.productModal__amountButton:disabled {
  cursor: auto;
  border: 1px solid rgb(235, 235, 235);
}
.productModal__amount {
  font-weight: 600;
  margin-left: 8px;
  margin-right: 8px;
}

.productModal__amountAddSubIcons {
  color: #1a1a1a;
  width: 16px !important;
}
.productModal__variantsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.productModal__variant {
  display: flex;
  margin: 8px;
}
.productModal__variantLabel {
  margin-right: 20px;
  color: rgb(78, 78, 78);
}
.productModal__sizeButton {
  font-weight: 600;
  background-color: white;
  color: rgb(78, 78, 78);
  border: none;
  border-radius: 3px;
  margin-right: 2px;
  margin-left: 2px;
  padding: 6px 6px 6px 6px;
  min-width: 32px;
  cursor: pointer;
}
.productModal__sizeButton:disabled {
  color: rgb(189, 189, 189);
  cursor: auto;
}
.activeSizeButton {
  font-weight: 600;
  background-color: #1a1a1a;
  color: rgb(248, 248, 248);
  border: none;
  border-radius: 3px;
  margin-right: 2px;
  margin-left: 2px;
  padding: 6px 6px 6px 6px;
  min-width: 32px;
}

.productModal__soldOut {
  background-color: rgb(235, 15, 15);
  color: white;
  border-radius: 3px;
  font-weight: 600;
  padding: 0.2rem 0.6rem;
  width: 6rem;
  text-align: center;
}

@media (min-width: 350px) {
  .product__img {
    height: 280px;
  }
  .productModal__imageGallery {
    height: 250px;
  }
  .productModal__name {
    font-size: 1.2rem;
  }
  .productModal__name {
    font-size: 1.2rem;
  }
  .productModal__descriptionstock {
    margin-bottom: 20px;
  }
  .productModal__addButton {
    margin: 20px;
  }
}
@media (min-width: 520px) {
  .product__img {
    height: 320px;
  }
  .product__title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 4px;
  }
  .product__price {
    font-size: 1.2rem;
  }
}
@media (min-width: 680px) {
  .product__img {
    height: 360px;
  }
  .product {
    margin: 20px;
  }
}
